<template>
  <div>
    <v-sheet v-if="wordTemplates" flat class="pa-5" color="canvas">
      <WordTemplateInfo
        v-for="wordTemplate of wordTemplates"
        :key="wordTemplate.id"
        :wordTemplate="wordTemplate"
        :template="template"
        :locator="locator"
        :showIds="showIds"
        @templatesChanged="onTemplatesChanged"
      />
      <!-- create new bilag -->
      <v-dialog v-model="newTemplateDialog" max-width="1000">
        <template #activator="{ props }">
          <v-btn class="primary-button" v-bind="props">Tilføj bilag</v-btn>
        </template>
        <v-card title="Tilføj bilag">
          <v-card-text>
            <v-text-field v-model="newTemplateName" label="Navn på bilag" variant="outlined" density="compact" />
            <v-file-input
              v-model="newTemplateFile"
              class="mt-2"
              density="compact"
              show-size
              label="Select word document"
              variant="outlined"
              hint="Upload a word template"
              persistent-hint
            ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="secondary-button-mini" @click="newTemplateDialog = false">Annuller</v-btn>
            <v-btn class="primary-button-mini" @click="createNewTemplate()">Gem</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
        class="ml-4 primary-button"
        @click="downloadWordTemplates()"
      >
        Download alle Word-skabeloner
      </v-btn>
    </v-sheet>
    <LoadingSpinner loadingMessage="Vent venligst" :visible="isLoading" />
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import {
  DeepQuestionnaireTemplate,
  LoadingSpinner,
  downloadFile,
} from '@dims/components';
import { WordTemplate } from '@/models';
import templateServices from './templateServices';
import QuestionLocator from './Editor/QuestionLocator';
import WordTemplateInfo from './WordTemplateInfo.vue';
import { useStore } from '@/store/store';

const { template, locator, showIds } = defineProps<{
  template: DeepQuestionnaireTemplate,
  locator: QuestionLocator,
  showIds: boolean,
}>();
const wordTemplates = ref<WordTemplate[] | null>(null);
const newTemplateDialog = ref(false);
const newTemplateName = ref<string | null>(null);
const newTemplateFile = ref<File>();
const isLoading = ref(false);
const store = useStore();

async function load() {
  isLoading.value = true;
  await loadWordTemplates();
  isLoading.value = false;
}
void load();

async function loadWordTemplates() {
  wordTemplates.value = await templateServices.getWordTemplates(template);
}

async function createNewTemplate() {
  // TODO: Form validation
  if (newTemplateFile.value && newTemplateName.value) {
    try {
      isLoading.value = true;
      await templateServices.createNewWordTemplate(
        template,
        newTemplateFile.value,
        newTemplateName.value,
      );
      newTemplateDialog.value = false;
      await loadWordTemplates();
    } catch (e) {
      store.setErrorMessage('Kunne ikke hente templates', e);
    } finally {
      isLoading.value = false;
    }
  }
}

async function onTemplatesChanged() {
  isLoading.value = true;
  await loadWordTemplates();
  isLoading.value = false;
}

async function downloadWordTemplates() {
  isLoading.value = true;
  const zip = await templateServices.downloadFiles(template.id);
  downloadFile(zip, 'Skabeloner.zip');
  isLoading.value = false;
}

</script>
