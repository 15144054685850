<template>
  <v-data-table
    class="elevation-1 my-5"
    :headers="headers"
    :items="items"
    no-data-text="Ingen afkrydsningsfelter"
    no-results-text="Ingen afkrydsningsfelter"
    :items-per-page-options="[5, 10]"
  >
    <template #top>
      <v-toolbar flat color="canvas">
        Afkrydsningsfelt
        <v-chip v-if="items" class="mx-2" size="small">{{ items.length }}</v-chip>
        <v-row class="align-center ml-2">
          <v-col>
            <v-text-field
              v-model="text"
              label="Indsæt tekst"
              hide-details
              variant="outlined"
              density="compact"
              :disabled="readonly"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn
              @click="addItem()"
              class="primary-button-mini"
              :disabled="!text || readonly"
            >Tilføj</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn @click="deleteItem(item)" class="float-right" :disabled="readonly" icon="mdi-delete" />
    </template>
  </v-data-table>
</template>

<script setup lang="ts">
import { SectionQuestionDefinition } from '@dims/components';
import { computed, ref } from 'vue';
import { v4 as newUUID } from 'uuid';

interface CheckboxItem { id: string; title: string }

const { question, readonly = false } = defineProps<{
  question: SectionQuestionDefinition,
  readonly?: boolean }>();

const items = computed((): CheckboxItem[] => (question.questions ?? []).map((q) => ({ title: q.title, id: q.questionDefinitionId })));

const text = ref('');
const headers = [
  {
    title: 'Titel',
    value: 'title',
  },
  {
    title: 'ID',
    value: 'id',
  },
  {
    title: '',
    value: 'actions',
  },
];

const questions = computed(() => question.questions ?? []);

function addItem() {
  const id = newUUID();
  questions.value.push({
    questionDefinitionId: id,
    name: id,
    questionType: 'Boolean',
    variant: 'checkbox-list',
    title: text.value,
    text: '',
  });
  text.value = '';
}

function deleteItem(item: CheckboxItem) {
  const questionsList = question.questions ?? [];
  question.questions = [...questionsList.filter((q) => q.questionDefinitionId !== item.id)];
}

</script>
