<template>
  <v-dialog v-model="visible" :width="width">
    <v-card class="pa-5" v-if="visible">
      <template #title>
        <h2>{{ title }}</h2>
      </template>
      <template #append>
        <v-btn @click="visible = false" icon="mdi-close" />
      </template>
      <v-card-text><slot name="default" /></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary-button" @click="visible = false">
          OK
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <template #activator="{ props }"><slot name="activator" :props="props" /></template>
  </v-dialog>
</template>
<script setup lang="ts">

const { title, width = 400 } = defineProps<{ title: string, width?: number }>();
const visible = defineModel<boolean>();

</script>
