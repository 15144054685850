<template>
  <template v-if="template">
    <v-row class="d-flex justify-end align-center">
      <h2 class="mt-0">{{ template.name }}</h2>
      <v-spacer></v-spacer>
      <v-btn @click="collapse" variant="text" color="accent">Fold ind</v-btn>|
      <v-btn @click="expand" variant="text" color="accent">Fold ud</v-btn>
      <v-switch
        v-model="splitscreen"
        label="Preview"
        inset
        density="compact"
        hide-details
        color="primary"
      />
      <v-switch
        v-model="showQuestionTypes"
        class="mx-4"
        label="Vis typer"
        inset
        density="compact"
        hide-details
        color="primary"
      />
      <v-switch
        v-model="showIds"
        class="mx-4"
        label="Vis ID'er"
        inset
        density="compact"
        hide-details
        color="primary"
      />
      <v-switch
        v-model="showDependencies"
        class="mx-4"
        label="Vis betingelser"
        inset
        density="compact"
        hide-details
        color="primary"
      />
      <DependenciesOverview :locator="locator" />
      <TemplateBackupDialog
        :template="template"
        @updateTemplate="updateTemplate"
        :isLoading="isLoading"
      />
      <v-dialog width="1400" v-model="previewDialog">
        <template #activator="{ props }">
          <v-btn v-bind="props" class="secondary-button-mini">
            <v-icon size="small" class="mr-2">mdi-file-outline</v-icon>
            Preview
          </v-btn>
        </template>
        <v-card class="pa-10" color="canvas">
          <TemplatePreview
            v-if="preview"
            :preview="preview"
            :template="template"
            @updatePreview="onUpdatePreview"
          />
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs v-model="editorTab" align-tabs="center" bg-color="transparent">
          <v-tab color="primary" value="Questionnaire">Formular</v-tab>
          <v-tab color="primary" value="WordTemplates">Word-skabeloner</v-tab>
        </v-tabs>
      </v-col>
      <v-col v-if="splitscreen">
        <v-tabs v-model="previewTab" align-tabs="center" bg-color="transparent">
          <v-tab value="Preview">
            <h2 class="mt-0">PREVIEW</h2></v-tab>
        </v-tabs>
      </v-col>
      <v-col class="text-right no-top-padding">
        <v-btn
          class="secondary-button-mini mt-5 bg-skiGrey"
          size="large"
          @click="excelExport()"
        >
          Eksporter til Excel
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="content pa-0">
        <v-tabs-window v-model="editorTab" class="bg-canvas">
          <v-tabs-window-item value="Questionnaire">
            <TemplateTreeView
              :template="template"
              :questions="templateQuestions"
              :showIds="showIds"
              :showQuestionTypes="showQuestionTypes"
              :showDependencies="showDependencies"
              :locator="locator"
              :expandedStateKeeper="expandedStateKeeper"
              @addQuestion="addQuestion"
              @editQuestion="editQuestion"
              @deleteQuestion="onDeleteQuestion"
              @copyQuestion="copyQuestion"
              @updateTemplate="updateTemplate"
              :isLoading="isLoading"
            />
            <!-- add root -->
            <v-btn
              class="primary-button-mini mt-5"
              size="large"
              @click="addRootQuestion()"
            >
              <v-icon>mdi-plus-thick</v-icon>Tilføj niveau 1
            </v-btn>
            <QuestionDialog
              v-model="showQuestionDialog"
              :question="selectedQuestion"
              :template="template"
              :locator="locator"
              :expandedStateKeeper="expandedStateKeeper"
              :editing="isEditingDialog"
              @fetchTemplate="fetchTemplate"
              @updateTemplate="updateTemplate"
              :root="selectedQuestion === null"
            />
            <CopyQuestion
              v-if="selectedQuestion"
              v-model="showCopyDialog"
              :question="selectedQuestion"
              :locator="locator"
              :expandedStateKeeper="expandedStateKeeper"
              :template="template"
              @updateTemplate="updateTemplate"
            />
            <DeleteDialog
              v-if="selectedQuestion"
              v-model="showDeleteDialog"
              :question="selectedQuestion"
              @deleteQuestion="deleteQuestion"
            />
          </v-tabs-window-item>
          <v-tabs-window-item value="WordTemplates">
            <WordTemplates
              :template="template"
              :locator="locator"
              :showIds="showIds"
            />
          </v-tabs-window-item>
        </v-tabs-window>
      </v-col>
      <v-col v-if="splitscreen" class="content pa-0 ml-5">
        <TemplatePreview
          v-if="preview"
          :preview="preview"
          :template="template"
          @updatePreview="onUpdatePreview"
          data-cy="preview-tab"
        />
      </v-col>
    </v-row>
  </template>
  <LoadingSpinner
    loadingMessage="Vent venligst"
    :visible="!template || isLoading"
  />
</template>

<script setup lang="ts">
import { ref, computed, Ref, provide } from 'vue';
import {
  DeepQuestionnaire,
  DeepQuestionnaireTemplate,
  LoadingSpinner,
  PushDeepAnswer,
  QuestionDefinition,
  downloadFile,
  injectionKeys,
} from '@dims/components';
import TemplatePreview from '../Preview/TemplatePreview.vue';
import WordTemplates from '../WordTemplates.vue';
import TemplateTreeView from './TemplateTreeView.vue';
import QuestionDialog from './QuestionDialog.vue';
import templateServices from '../templateServices';
import { collectPreviewAnswers as collectPreviewAnswersFrom } from '../utilities';
import QuestionLocator from './QuestionLocator';
import validateStructure from './validateStructure';
import TemplateBackupDialog from './TemplateBackup/TemplateBackupDialog.vue';
import DependenciesOverview from './QuestionForm/DependenciesOverview.vue';
import { useStore } from '@/store/store';
import CopyQuestion from './QuestionForm/CopyQuestion/CopyQuestion.vue';
import DeleteDialog from './DeleteDialog.vue';
import ExpandedStateKeeper from './ExpandedStateKeeper';

const { templateId } = defineProps<{ templateId: string }>();
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const template = ref<DeepQuestionnaireTemplate>(undefined!);
// TODO: Migrate: cast is a hack, dont know why it is needed. Possibly something with private methods?
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const locator = ref<QuestionLocator>(undefined!) as Ref<QuestionLocator>;
const preview = ref<DeepQuestionnaire | null>(null);
const previewDialog = ref(false);
const splitscreen = ref(false);
const isLoading = ref(false);
const showIds = ref(false);
const showQuestionTypes = ref(true);
const showDependencies = ref(true);
const editorTab = ref<'Questionnaire' | 'WordTemplates'>('Questionnaire');
const previewTab = ref(0);
const store = useStore();
// dialogs
const showQuestionDialog = ref(false);
const showCopyDialog = ref(false);
const showDeleteDialog = ref(false);
const selectedQuestion = ref<QuestionDefinition | null>(null);
const isEditingDialog = ref(false);
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const expandedStateKeeper = ref<ExpandedStateKeeper>(undefined!);

// used by preview components
const debug = ref(false);
provide(injectionKeys.debug, debug);

const templateQuestions = computed(() => template.value.questions);

async function load() {
  await fetchTemplate();
}
void load();

async function fetchTemplate() {
  isLoading.value = true;
  template.value = await templateServices.getTemplate(templateId);
  validateStructure(template.value);
  locator.value = new QuestionLocator(template.value);
  expandedStateKeeper.value = new ExpandedStateKeeper(template.value.questions, false);
  await updatePreview([]);
  isLoading.value = false;
}

function expand() {
  expandedStateKeeper.value.setAll(true);
}

function collapse() {
  expandedStateKeeper.value.setAll(false);
}

async function excelExport() {
  isLoading.value = true;
  const excelFile = await templateServices.excelExport(templateId);
  downloadFile(excelFile, `${template.value.agreementName} ${templateId}.xlsx`);
  isLoading.value = false;
}

async function updateTemplate(newTemplate: DeepQuestionnaireTemplate) {
  isLoading.value = true;
  template.value = await templateServices.updateTemplate(
    newTemplate.id,
    newTemplate,
  );
  locator.value = new QuestionLocator(template.value);

  preview.value = null; // Updating the template might invalidate the preview
  await updatePreview([]);

  isLoading.value = false;
}

async function updatePreview(answers: PushDeepAnswer[]) {
  preview.value = await templateServices.getPreview(templateId, answers);
}

async function deleteQuestion(id: string) {
  try {
    isLoading.value = true;
    const question = locator.value.getQuestion(id);
    const questionViewModel = locator.value.getQuestionViewModel(question);
    if (questionViewModel === null) return;
    const questionIndex = questionViewModel.index;
    let questionCollection: QuestionDefinition[];
    if (questionViewModel.parent?.questions) {
      questionCollection = questionViewModel.parent.questions;
    } else {
      questionCollection = template.value.questions;
    }
    // Double-checking:
    if (questionCollection[questionIndex]?.questionDefinitionId === id) {
      questionCollection.splice(questionIndex, 1);
    }
    await updateTemplate(template.value);
  } catch (error) {
    store.showSnackbarError('Kunne ikke slette spørgsmål', error);
  } finally {
    isLoading.value = false;
  }
}

async function onUpdatePreview() {
  console.info('preview update');
  const answers = collectPreviewAnswers();
  await updatePreview(answers);
}
/** Collect answers from the preview before update */
function collectPreviewAnswers(): PushDeepAnswer[] {
  if (!preview.value) {
    return [];
  }
  return collectPreviewAnswersFrom(preview.value);
}
function addRootQuestion() {
  selectedQuestion.value = null;
  isEditingDialog.value = false;
  showQuestionDialog.value = true;
}
function addQuestion(question: QuestionDefinition) {
  selectedQuestion.value = question;
  isEditingDialog.value = false;
  showQuestionDialog.value = true;
}
function editQuestion(question: QuestionDefinition) {
  selectedQuestion.value = question;
  isEditingDialog.value = true;
  showQuestionDialog.value = true;
}
function onDeleteQuestion(question: QuestionDefinition) {
  selectedQuestion.value = question;
  showDeleteDialog.value = true;
}
function copyQuestion(question: QuestionDefinition) {
  selectedQuestion.value = question;
  showCopyDialog.value = true;
}

</script>
<style scoped>
:deep(.v-tabs-slider-wrapper) {
  display: none;
}

:deep(.v-tab--active h2) {
  font-weight: bold;
}

.content {
  height: calc(100vh - 250px);
  overflow: auto;
}

.v-input {
  flex: 0 1 auto;
}

.no-top-padding {
  padding-top: 0;
}
</style>
