<template>
  <v-container>
    <h1>Formularværktøj {{ agreementName }}</h1>
    <h3 class="mt-2 mb-4">Skabeloner (questionnaires)</h3>
    <template v-if="templates">
      <v-card v-if="templates.length === 0">
        <v-btn @click="createFirstVersion()" class="primary-button mx-2 my-2">Opret første version</v-btn>
      </v-card>

      <v-card
        v-for="hist in templates"
        :key="hist.name"
        :title="hist.name"
        class="mb-4"
      >
        <template #title>
          <h3 class="mt-0">{{ hist.name }}</h3>
        </template>
        <template #append>
          <v-chip v-if="hist.isDefault" variant="flat" color="accent" class="mr-1">Aktiv</v-chip>
          <v-chip>{{ hist.target === 'DirectAward' ? 'Direkte tildeling' : 'DIS' }}</v-chip>
        </template>
        <v-card-text>

          <v-divider></v-divider>
          <!-- draft -->
          <h4 class="mt-6 mb-3">Kladde</h4>
          <div v-if="hist.draft">
            <v-table fixed-header density="compact" class="my-2">
              <thead>
                <tr>
                  <th class="text-left" width="180px">Senest opdateret</th>
                  <th>Senest opdateret af</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>ID</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="hist.draft.id" class="my-1">
                  <td class="text-left" width="180px">{{ displayLastUpdated(hist.draft) }}</td>
                  <td>{{ hist.draft.lastUpdatedBy?.fullName }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {{ hist.draft.id.slice(0, 8) }}...
                    <CopyToClipboard :text="hist.draft.id" /></td>
                </tr>
              </tbody>
            </v-table>
            <v-btn @click="editTemplate(hist.draft)" class="primary-button-mini ml-2"><v-icon>mdi-pencil-outline</v-icon>Rediger kladde</v-btn>
            <!-- delete draft -->
            <ConfirmDialog
              activatorText="Slet kladde"
              text="Vil du slette kladden?"
              @confirmed="deleteDraft(hist.draft)"
            />
            <!-- publish draft disabled for 0222 sandbox #17707 -->
            <PublishDraftDialog v-if="!(agreementName === '02220022' && hist.name.toLowerCase().indexOf('sandbox') > 0)" :draft="hist.draft" @updated="refresh()" />
            <CopyToEnvironment :templateId="hist.draft.id" class="ml-2" />
          </div>
          <div v-else>
            <i>Ingen kladde</i>
            <div>
              <v-btn
                v-if="hist.current"
                @click="createVersion(hist.current)"
                class="secondary-button-mini"
              >Dan ny kladde</v-btn>
            </div>
          </div>
          <br />
          <br />

          <v-divider></v-divider>
          <!-- current -->
          <h4 class="mt-6 mb-3">Aktiv version</h4>
          <div v-if="hist.current">
            <v-table fixed-header density="compact" class="my-2">
              <thead>
                <tr>
                  <th>Offentliggjort den</th>
                  <th>Offentliggjort af</th>
                  <th>Version</th>
                  <th>Versions-ID</th>
                  <th>View</th>
                  <th>Antal indkøb</th>
                  <th>ID</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="hist.current.id" class="my-1">
                  <td>{{ displayPublishedDate(hist.current) }}</td>
                  <td>{{ hist.current.lastUpdatedBy?.fullName }}</td>
                  <td class="text-right">{{ hist.current.publishedVersionNumber }}</td>
                  <td>{{ displayExtendedVersionId(hist.current) }}</td>
                  <td><v-btn size="x-small" icon="mdi-file-eye-outline" variant="text" @click="preview(hist.current)"></v-btn></td>
                  <td>{{ hist.current.uses.length }}</td>
                  <td>   {{ hist.current.id.slice(0, 8) }}...
                    <CopyToClipboard :text="hist.current.id" /></td>
                </tr>
              </tbody>
            </v-table>
            <CopyToEnvironment :templateId="hist.current.id" />
          </div>
          <div v-else>
            <i>Ingen aktiv version</i>
          </div>
          <br />
          <br />

          <v-divider></v-divider>
          <!-- retired -->
          <h4 class="mt-6 mb-3">Tidligere versioner</h4>
          <div v-if="hist.oldVersions.length > 0">
            <v-table height="300px" fixed-header density="compact">
              <thead>
                <tr>
                  <th>Offentliggjort den</th>
                  <th>Offentliggjort af</th>
                  <th>Version</th>
                  <th>Versions-ID</th>
                  <th>View</th>
                  <th>Antal indkøb</th>
                  <th>ID</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="version in hist.oldVersions.toSorted(sortVersions)" :key="version.id" class="my-1">
                  <td>{{ displayPublishedDate(version) }}</td>
                  <td>{{ version.lastUpdatedBy?.fullName }}</td>
                  <td class="text-right">{{ version.publishedVersionNumber }}</td>
                  <td>{{ displayExtendedVersionId(version) }}</td>
                  <td><v-btn size="x-small" icon="mdi-file-eye-outline" variant="text" @click="preview(version)"></v-btn></td>
                  <td>{{ version.uses.length }}</td>
                  <td>
                    {{ version.id.slice(0, 8) }}...
                    <CopyToClipboard :text="version.id" />
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
          <div v-else>
            <i>Ingen tidligere versioner</i>
          </div>
        </v-card-text>
      </v-card>
    </template>
    <LoadingSpinner loadingMessage="Vent venligst" :visible="isLoading" />
  </v-container>
  <DimsDialog v-model="showPreview" title="Preview" :width="1000">
    <VersionPreview v-if="selectedPreviewTemplate" :template="selectedPreviewTemplate" />
  </DimsDialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { DeepQuestionnaireTemplate, LoadingSpinner, AwardForm } from '@dims/components';
import { DateTime } from 'luxon';
import templateServices from '@/components/Templates/templateServices';
import { DeepQuestionnaireTemplateSummary } from '@/components/Templates/DeepQuestionnaireTemplateSummary';
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue';
import PublishDraftDialog from './PublishDraftDialog.vue';
import CopyToEnvironment from './CopyToEnvironment.vue';
import CopyToClipboard from '@/components/shared/CopyToClipboard.vue';
import VersionPreview from '@/components/Templates/Preview/VersionPreview.vue';
import DimsDialog from '@/components/Templates/Editor/DimsDialog.vue';
import agreementsService from '@/agreementsService';

interface TemplateHistory {
  readonly name: string;
  target?: AwardForm;
  isDefault: boolean;
  draft?: DeepQuestionnaireTemplateSummary;
  current?: DeepQuestionnaireTemplateSummary;
  oldVersions: DeepQuestionnaireTemplateSummary[];
}

const props = defineProps<{ agreementName: string }>();
const templates = ref<TemplateHistory[] | null>(null);
const isLoading = ref(false);
const router = useRouter();

const showPreview = ref(false);
const selectedPreviewTemplate = ref<DeepQuestionnaireTemplate>();

async function load() {
  isLoading.value = true;
  const agreements = await agreementsService.getAgreements();
  const agreementConfiguration = agreements.first((a) => a.agreementName === props.agreementName);

  const templatesList = await templateServices.getTemplates(props.agreementName);
  const questionnaireTemplateHistories: TemplateHistory[] = [];
  for (const template of templatesList) {
    let questionnaireTemplateHistory = questionnaireTemplateHistories.find((s) => s.name === template.name);
    if (!questionnaireTemplateHistory) {
      questionnaireTemplateHistory = { name: template.name, oldVersions: [], target: template.target, isDefault: false };
      questionnaireTemplateHistories.push(questionnaireTemplateHistory);
    }
    if (template.state === 'Draft') {
      questionnaireTemplateHistory.draft = template;
    } else if (template.state === 'Current') {
      questionnaireTemplateHistory.current = template;
      if (agreementConfiguration.deepQuestionnairesList?.find((q) => q.specificationTemplateId === template.id && template.target === q.target)) {
        questionnaireTemplateHistory.isDefault = true;
      }
    } else {
      questionnaireTemplateHistory.oldVersions.push(template);
    }
  }
  // We sort to get the active at the top
  templates.value = questionnaireTemplateHistories.toSorted((a, b) => ((a.oldVersions.length > b.oldVersions.length) ? -1 : 1));
  isLoading.value = false;
}
void load();

async function preview(version: DeepQuestionnaireTemplateSummary) {
  const template = await templateServices.getTemplate(version.id);
  selectedPreviewTemplate.value = template;
  showPreview.value = true;
}

function displayLastUpdated(template?: DeepQuestionnaireTemplateSummary) {
  if (template) {
    return DateTime.fromISO(template.lastUpdated).toFormat('dd-MM-yyyy HH:mm');
  }
  return '';
}

function displayPublishedDate(template: DeepQuestionnaireTemplateSummary) {
  if (template.publishedDate) {
    return DateTime.fromISO(template.publishedDate).toFormat('dd-MM-yyyy HH:mm');
  }
  return '';
}

function displayExtendedVersionId(template: DeepQuestionnaireTemplateSummary) {
  return template.publishedVersionNumber && template.publishedDate
    ? `${props.agreementName}-${template.publishedVersionNumber}-${DateTime.fromISO(template.publishedDate).toFormat('ddMMyy')}` : '';
}

async function editTemplate(template?: DeepQuestionnaireTemplateSummary) {
  if (template && template.state === 'Draft') {
    await router.push(`/template/${template.id}`);
  }
}

async function createFirstVersion() {
  await templateServices.createFirstTemplateVersion(props.agreementName);
  await load();
}

async function createVersion(template?: DeepQuestionnaireTemplateSummary) {
  if (template) {
    await templateServices.createNewTemplateVersion(template.id);
    await load();
  }
}

async function deleteDraft(template?: DeepQuestionnaireTemplateSummary) {
  if (template) {
    await templateServices.deleteTemplate(template.id);
    await load();
  }
}

async function refresh() {
  await load();
}

function sortVersions(t1:DeepQuestionnaireTemplateSummary, t2: DeepQuestionnaireTemplateSummary) {
  if (t1.publishedDate && t2.publishedDate) {
    return (t1.publishedDate < t2.publishedDate) ? 1 : -1;
  } if (t1.publishedDate) {
    return -1;
  } if (t2.publishedDate) {
    return 1;
  }
  return (t1.lastUpdated < t2.lastUpdated) ? 1 : -1;
}

</script>

<style scoped>

.row-pointer :deep(tbody tr:hover) {
  cursor: pointer;
}

td {
  background-color: #E2E4E3;
  color: #63756B;
}

td:nth-child(2) {
  min-width: 30ch;
}

td:last-child{
  font-family: monospace;
  width: 20ch;
}

h3 {
  line-height: 1.3
}
</style>
