<template>
  <v-card class="mb-4">
    <template #title>
      <h3 v-if="!edit" class="mt-0">
        {{ wordTemplate.name }}
      </h3>
      <div v-else class="mb-5">
        <v-textarea
          v-model="editName"
          hide-details
          :disabled="isLoading"
          class="mb-2"
          auto-grow
          rows="1"
          label="Rediger navn"
          variant="outlined"
        ></v-textarea>
        <v-btn
          @click="commitEdit"
          class="primary-button-mini"
          :loading="isLoading"
          :disabled="editName === ''"
        >
          Gem
        </v-btn>
        <v-btn
          @click="cancelEdit"
          :disabled="isLoading"
          class="ml-2 secondary-button-mini"
        >
          Annuller
        </v-btn>
      </div>
    </template>
    <template #append>
      <v-spacer></v-spacer>
      <v-btn @click="startEdit()" v-if="!edit" class="secondary-button-mini">
        Rediger titel <v-icon size="small" end>mdi-pencil</v-icon>
      </v-btn>
      <v-btn @click="deleteTemplate(wordTemplate)" class="ml-2 secondary-button-mini">
        Slet bilag <v-icon size="small" end>mdi-trash-can-outline</v-icon>
      </v-btn>
    </template>
    <div v-if="showIds">{{ wordTemplate.id }}</div>
    <div class="d-flex ml-4">
      <v-chip color="primary" v-for="(tag, ix) in wordTemplate.tags" :key="ix" class="mr-2" closable @click:close="removeTag(ix)">
        {{ tag }}
      </v-chip>
      <v-chip @click="startCreateNewTag()">
        <v-icon class="pa-0" size="small">mdi-plus</v-icon>
      </v-chip>
      <div v-if="editNewTag" class="ml-2">
        <v-text-field
          density="compact"
          v-model="newTag"
          hide-details
          :disabled="isLoading"
          class="mb-2"
          auto-grow
          rows="1"
          label="Tilføj tag"
          variant="outlined"
        >
          <template #append>
            <div class="d-flex">
              <v-icon
                color="primary"
                @click="cancelEditTag"
              >
                mdi-close
              </v-icon>
              <v-btn
                color="primary"
                :disabled="newTag === ''"
                @click="commitNewTag"
                @keyup.enter="commitNewTag"
                icon="mdi-check"
              />
            </div>
          </template>
        </v-text-field>
      </div>
    </div>
    <v-card-text class="pt-0">
      <DependenciesDisplay
        :locator="locator"
        :condition="wordTemplate.dependsOn"
        @conditionChange="onConditionChange(wordTemplate, $event)"
      />
      <div class="d-flex align-center">
        <v-btn class="secondary-button-mini" @click="downloadWordFile(wordTemplate)">
          Download skabelon til redigering
        </v-btn>
        <v-btn
          class="secondary-button-mini mx-4"
          @click="downloadWordDummy(wordTemplate)"
        >Download dummy</v-btn>
        <v-btn
          class="secondary-button-mini"
          @click="displayHtmlPreview(wordTemplate)"
        >Preview dummy</v-btn>
      </div>
    </v-card-text>
    <DocumentPreviewDialog
      :title="wordTemplate.name"
      :html="previewHtml"
    />
  </v-card>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import {
  Condition,
  downloadFile,
} from '@dims/components';
import { WordTemplate } from '@/models';
import DependenciesDisplay from './Editor/QuestionForm/DependenciesDisplay.vue';
import templateServices from './templateServices';
import QuestionLocator from './Editor/QuestionLocator';
import DocumentPreviewDialog from './Preview/DocumentPreviewDialog.vue';

const emit = defineEmits<{ templatesChanged: [] }>();
const props = defineProps<{
  wordTemplate: WordTemplate,
  locator: QuestionLocator,
  showIds: boolean,
}>();
const isLoading = ref(false);
const edit = ref(false);
const editName = ref('');
const newTag = ref('');
const editNewTag = ref(false);
const previewHtml = ref<string | null>(null);

async function deleteTemplate(template: WordTemplate) {
  isLoading.value = true;
  await templateServices.deleteWordTemplate(template);
  isLoading.value = false;
  emit('templatesChanged');
}

async function downloadWordFile(wordTemplate: WordTemplate) {
  isLoading.value = true;
  const doc = await templateServices.downloadFile(wordTemplate);
  downloadFile(doc, `${wordTemplate.name}.docx`);
  isLoading.value = false;
}

async function downloadWordDummy(wordTemplate: WordTemplate) {
  isLoading.value = true;
  const blob = await templateServices.downloadWordDummy(wordTemplate);
  downloadFile(blob, wordTemplate.name);
  isLoading.value = false;
}

async function displayHtmlPreview(wordTemplate: WordTemplate) {
  isLoading.value = true;
  previewHtml.value = null;
  const html = await templateServices.getDummyWordHtmlPreview(wordTemplate);
  previewHtml.value = html;
  isLoading.value = false;
}

async function onConditionChange(info: WordTemplate, condition: Condition | undefined) {
  await updateTemplateRecord({ ...info, dependsOn: condition });
}
function startEdit() {
  editName.value = props.wordTemplate.name;
  edit.value = true;
}

function startCreateNewTag() {
  newTag.value = '';
  editNewTag.value = true;
}

function cancelEditTag() {
  editNewTag.value = false;
  newTag.value = '';
}

async function commitNewTag() {
  editNewTag.value = false;
  if (newTag.value) {
    if (!props.wordTemplate.tags) {
      props.wordTemplate.tags = [];
    }
    props.wordTemplate.tags.push(newTag.value);
    newTag.value = '';
    await updateTemplateRecord(props.wordTemplate);
    emit('templatesChanged');
  }
}

async function removeTag(ix: number) {
  if (props.wordTemplate.tags) {
    props.wordTemplate.tags.splice(ix, 1);
    await updateTemplateRecord(props.wordTemplate);
    emit('templatesChanged');
  }
}

async function updateTemplateRecord(info: WordTemplate) {
  await templateServices.updateWordTemplateRecord(info);
  emit('templatesChanged');
}
function cancelEdit() {
  edit.value = false;
  editName.value = props.wordTemplate.name;
}
async function commitEdit() {
  edit.value = false;
  props.wordTemplate.name = editName.value;
  await updateTemplateRecord(props.wordTemplate);
  emit('templatesChanged');
}

</script>
