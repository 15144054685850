<template>
  <div>
    <template
      v-if="variant === 'requirement-variant-3-contract'
        || variant === 'requirement-variant-3-evaluation'
        || variant === 'requirement-variant-6'"
    >
      <v-radio-group v-model="defaultInclude" label="Default for 'skal kravet indgå'" :disabled="readonly">
        <v-radio value="true" label="Ja" />
        <v-radio value="false" label="Nej" />
      </v-radio-group>
    </template>
    <template v-if="variant === 'requirement-variant-6'">
      <v-card color="canvas">
        <v-card-text>
          <h2 class="mt-0 mb-4">
            Tekstfelt, hvis kravet indgår
          </h2>
          <v-text-field
            v-model="textFieldTitle"
            :readonly="readonly"
            variant="outlined"
            density="compact"
            label="Titel"
          />
          <RichTextEditor v-model="textFieldText" ref="richTextEditor" class="mb-5" :admin="true" />
        </v-card-text>
      </v-card>
    </template>
    <template v-if="variant === 'requirement-variant-3-evaluation'">
      <RichTextEditor
        v-model="evaluationText"
        label="Beskriv evalueringskriterie"
        ref="richTextEditor"
        :admin="true"
      />
    </template>
    <div v-if="supportsNumbering">
      <v-checkbox v-model="numbered" label="Indgår i kravs-nummerering" />
    </div>
    <div v-if="variant === 'requirement-variant-4'">
      <v-select
        v-model="allowedRequirementType"
        label="Vælg kravtype"
        :items="requirementOptions"
        single
      />
    </div>
    <div v-if="variant === 'requirement-variant-5'">
      <p>Kravet kan vælges til/fra af kunden.</p>
      <v-radio-group v-model="includeSelectType">
        <v-radio label="Kravet er fravalgt som udgangspunkt. Valg vises som en checkbox." value="checkbox" />
        <v-radio label="Kunden skal aktivt tage stilling til kravet. Vises som ja/nej radioknapper for kunden." value="radio" />
      </v-radio-group>
    </div>
  </div>
</template>
<script setup lang="ts">
import { watch, computed, ref } from 'vue';
import { SectionQuestionDefinition, ValueQuestionDefinition, RichTextEditor, VariantFieldType, AllowedRequirementTypesEnum } from '@dims/components';
import { getVariantQuestion } from './variantQuestions';

type IncludeSelectType = 'checkbox' | 'radio';

const { value, question, readonly = false } = defineProps<{
  // The cloned question
  value: SectionQuestionDefinition,
  // The underlying question (null if new question)
  question: SectionQuestionDefinition | null,
  readonly?: boolean
}>();

const includeSelectType = ref<IncludeSelectType>('checkbox');

if (value.variant === 'requirement-variant-5' && value.questions) {
  const includeSelector = value.questions.find((q) => q.variant === 'include-requirement');
  if (includeSelector) {
    if (includeSelector.questionType === 'Boolean') {
      includeSelectType.value = 'checkbox';
    } else if (includeSelector.questionType === 'BooleanOptions') {
      includeSelectType.value = 'radio';
    }
  }
}

watch(
  includeSelectType,
  (newValue: IncludeSelectType) => {
    if (value.variant === 'requirement-variant-5' && value.questions) {
      const includeSelector = value.questions.find((q) => q.variant === 'include-requirement');
      if (includeSelector) {
        if (newValue === 'checkbox') {
          includeSelector.questionType = 'Boolean';
        } else {
          includeSelector.questionType = 'BooleanOptions';
        }
      }
    }
  },
);

const requirementOptions: { title: string; value: AllowedRequirementTypesEnum }[] = [
  {
    title: 'Kunden skal selv vælge',
    value: 'CustomersChoice',
  },
  {
    title: 'Kontrakt krav',
    value: 'Contract',
  },
  {
    title: 'Evalueringskrav',
    value: 'Evaluation',
  }];

const variant = computed(() => value.variant);

function getVariantChildQuestion(variant1: VariantFieldType) {
  return getVariantQuestion(value, variant1);
}

const getRepeaterQuestion = computed((): ValueQuestionDefinition => {
  const q = value.questions?.find((qu) => qu.questionType === 'Repeater');
  if (!q) {
    throw new Error('Repeater question not found');
  }
  return q;
});

const allowedRequirementType = computed({
  get() {
    return getRepeaterQuestion.value.variantData?.allowedRequirementTypes === undefined ? 'CustomersChoice' : getRepeaterQuestion.value.variantData.allowedRequirementTypes;
  },

  set(val: string) {
    getRepeaterQuestion.value.variantData = getRepeaterQuestion.value.variantData ?? {};
    getRepeaterQuestion.value.variantData.allowedRequirementTypes = val;
  },
});

const boolInclude = computed(() => getVariantChildQuestion('include-requirement') as ValueQuestionDefinition);

const defaultInclude = computed({
  get() {
    return boolInclude.value.defaultValue ?? 'false';
  },
  set(val: string) {
    boolInclude.value.defaultValue = val;
  },
});

const defaultEvaluationTextQuestion = computed(() => getVariantChildQuestion('default-evaluation-text'));

const evaluationText = computed({
  get() {
    return defaultEvaluationTextQuestion.value.text;
  },
  set(text: string) {
    defaultEvaluationTextQuestion.value.text = text;
  },
});

const textfield = computed(() => getVariantChildQuestion('requirement-textfield') as ValueQuestionDefinition);

const textFieldTitle = computed({
  get() {
    return textfield.value.title;
  },
  set(title: string) {
    textfield.value.title = title;
  },
});

const textFieldText = computed({
  get() {
    return textfield.value.text;
  },
  set(text: string) {
    textfield.value.text = text;
  },
});

const supportsNumbering = computed(() => (question ? question.variant === 'requirement-variant-1'
      || question.variant === 'requirement-variant-2'
      || question.variant === 'requirement-variant-3-contract'
      || question.variant === 'requirement-variant-3-evaluation'
      || question.variant === 'requirement-variant-4'
      || question.variant === 'requirement-variant-5'
      || question.variant === 'requirement-variant-6'
  : false));

const numbered = computed({
  get() {
    // When unspecified, default to true for requirements which supports numbering
    return question?.numbered ?? supportsNumbering.value;
  },

  set(val: boolean) {
    if (question) {
      // since true is default, we only save the false value
      question.numbered = !val ? false : undefined;
    }
  },
});

</script>
