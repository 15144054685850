<template>
  <v-data-table
    class="elevation-1 my-5"
    :headers="headers"
    :items="options"
    no-data-text="Ingen valgmuligheder"
    no-results-text="Ingen valgmuligheder"
    :items-per-page-options="[5, 10]"
  >
    <template #top>
      <v-toolbar flat color="canvas">
        Valgmuligheder
        <v-chip v-if="options" class="mx-2" size="small">{{ options.length }}</v-chip>
        <v-row class="align-center ml-2">
          <v-col>
            <v-text-field
              v-model="text"
              label="Indsæt tekst"
              hide-details
              variant="outlined"
              density="compact"
              :disabled="readonly"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn
              @click="addOption()"
              class="primary-button-mini"
              :disabled="!text || readonly"
              data-cy="addoptionbtn"
            >Tilføj</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn
        @click="deleteOption(item)"
        class="float-right"
        :disabled="readonly"
        icon="mdi-delete"
      />
    </template>
  </v-data-table>
</template>

<script setup lang="ts">
import { OptionDefinition, OptionsQuestionDefinition } from '@dims/components';
import { computed, ref } from 'vue';
import { v4 as newUUID } from 'uuid';

const emit = defineEmits<{ optionsChange: [OptionDefinition[]] }>();
const { question, readonly = false } = defineProps<{
  question: OptionsQuestionDefinition,
  readonly?: boolean,
}>();

const options = computed(() => question.options ?? []);

const text = ref('');
const headers = [
  {
    title: 'Titel',
    value: 'text',
  },
  {
    title: 'ID',
    value: 'optionId',
  },
  {
    title: '',
    value: 'actions',
  },
];

function addOption() {
  const option: OptionDefinition = {
    optionId: newUUID(),
    text: text.value,
  };
  emit('optionsChange', [...options.value, option]);
  text.value = '';
}

function deleteOption(option: OptionDefinition) {
  emit(
    'optionsChange',
    options.value.filter((o) => o.optionId !== option.optionId),
  );
}

</script>
