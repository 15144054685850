<!-- Display a template in read-only mode, typically for displaying an active or historical template -->
<template>
  <div>ID: {{ template.id }} </div>
  <v-tabs v-model="tab">
    <v-tab value="Questionnaire">Formular</v-tab>
    <v-tab value="WordTemplates">Word-skabeloner</v-tab>
  </v-tabs>
  <v-tabs-window v-model="tab">
    <v-tabs-window-item value="Questionnaire">
      <v-toolbar color="transparent">
        <v-switch v-model="showQuestionTypes" class="mx-4" label="Vis typer" color="primary" inset></v-switch>
        <v-switch v-model="showIds" class="mx-4" label="Vis ID'er" color="primary" inset></v-switch>
        <v-switch v-model="showDependencies" class="mx-4" label="Vis betingelser" color="primary" inset></v-switch>
      </v-toolbar>
      <TemplateTreeView
        v-if="template"
        :template="template"
        :questions="template.questions"
        :locator="locator"
        :expandedStateKeeper="expandedStateKeeper"
        :readonly="true"
        :showIds="showIds"
        :showQuestionTypes="showQuestionTypes"
        :showDependencies="showDependencies"
        :isLoading="isLoading"
        @viewQuestion="viewQuestion"
      />
      <QuestionDisplayDialog
        v-if="selectedQuestion"
        v-model="showQuestionDialog"
        :question="selectedQuestion"
        :template="template"
        :locator="locator"
      />
    </v-tabs-window-item>
    <v-tabs-window-item value="WordTemplates">
      <WordTemplatesPreview :template :showIds="false" :locator />
    </v-tabs-window-item>
  </v-tabs-window>
</template>

<script setup lang="ts">
import { DeepQuestionnaireTemplate, QuestionDefinition } from '@dims/components';
import { reactive, ref } from 'vue';
import ExpandedStateKeeper from '@/components/Templates/Editor/ExpandedStateKeeper';
import QuestionLocator from '@/components/Templates/Editor/QuestionLocator';
import TemplateTreeView from '@/components/Templates/Editor/TemplateTreeView.vue';
import QuestionDisplayDialog from '@/components/Templates/Editor/QuestionDisplayDialog.vue';
import WordTemplatesPreview from '../WordTemplatesPreview.vue';

const { template } = defineProps<{ template: DeepQuestionnaireTemplate }>();
const isLoading = ref(false);
const showQuestionDialog = ref(false);
const selectedQuestion = ref<QuestionDefinition | null>(null);
const showIds = ref(false);
const showQuestionTypes = ref(true);
const showDependencies = ref(false);

const tab = ref<'Questionnaire' | 'WordTemplates'>('Questionnaire');

const locator = new QuestionLocator(template);
const expandedStateKeeper = reactive(new ExpandedStateKeeper(template.questions, false));

function viewQuestion(question: QuestionDefinition) {
  selectedQuestion.value = question;
  showQuestionDialog.value = true;
}

</script>
