import { defineStore } from 'pinia';
import { Organization, RolesEnum, SSOUser, useBackend, useConfigStore, useErrorStore } from '@dims/components';

export interface ErrorMessage {
  message: string;
  error: unknown;
}

export interface AppState {
  isInitializing: boolean;
  isInitialized: boolean;
  user: SSOUser | null;
  organization: Organization | null;
}

const initialState = (): AppState => ({
  isInitializing: false,
  isInitialized: false,
  user: null as SSOUser | null,
  organization: null,
});

export const useStore = defineStore('admin', {
  state: () => initialState(),
  getters: {
    backend: () => useBackend(),
    configuration: () => useConfigStore().configuration,
    isIncognito(state: AppState) {
      return state.user === null;
    },
    showServiceBanner() {
      return false;
    },
    userIsAdmin(state: AppState) {
      return state.user?.roles?.includes(RolesEnum.ADMIN) === true;
    },
  },
  actions: {
    async ensureInitialized() {
      if (!this.isInitializing && !this.isInitialized) {
        await this.initialize();
      }
    },
    // initializes the store with a user
    // Assumes JWT token is defined
    async initialize() {
      console.log('Initializing store');
      this.isInitializing = true;
      const user = await this.backend.userService.getUser();
      if (!user) {
        // Happens if jwt token is not initialized or invalid
        throw new Error('User not defined');
      }
      this.setUser(user);

      this.isInitializing = false;
      this.isInitialized = true;
    },

    setUserSelectedRole(user: SSOUser) {
      this.setUser(user);
    },

    async logoutAction() {
      this.setUser(null);
      await this.backend.userService.logout();
    },

    setUser(user: SSOUser | null) {
      this.user = user;
      dispatchEvent(new Event('user-changed'));
    },
    setErrorMessage(message: string, error: unknown) {
      this.setError({ message, error });
      // this.setSnackbarText(message);
    },
    setError(error: ErrorMessage) {
      useErrorStore().setError(error);
    },
    showSnackbarError(message: string, error: unknown) {
      useErrorStore().showSolvedError(message, error);
    },
  },
});
